export const useTrustMary = () => {
	const config = useRuntimeConfig()

	const route = useRoute()

	if (route.meta.trustMary?.enable ?? false) {
		useHead({
			script: [
				{
					key: 'trustmary',
					src: `https://widget.trustmary.com/${config.public.TRUSTMARY_KEY}`,
					defer: true,
				},
			],
		})
	}
}
