import { nextTick } from 'vue'

export const useLazyFetchForApi = async (url, options) => {
	const runtimeConfig = useRuntimeConfig()

	let fetchOptions = {}

	if (options) {
		fetchOptions = { ...options }
	}

	if (!fetchOptions.headers) {
		fetchOptions.headers = {}
	}

	// default to only accept JSON
	fetchOptions.headers.Accept = 'application/json'

	// base url is the nitro route-rule which adds in the auth header via the server
	fetchOptions.baseURL = runtimeConfig.public.API_URL

	const accessToken = apiAccessToken()
	fetchOptions.headers.Authorization = `Bearer ${accessToken}`

	// This is a really annoying requirement!
	// without the await nextTick() call, the data object is not populated properly - you'd need to change the key
	// or call refresh manually. by waiting for the nextTick, the devalue calls are made properly.
	await nextTick()

	return useLazyFetch(
		augmentCarbarUrl(url, runtimeConfig.public.NODE_ENV),
		fetchOptions
	)
}
